import React, {FC, useEffect, useState} from 'react'
import ReactCodeInput from 'react-code-input'
import {useFormikContext} from "formik"
import getLocalizeString from "../../utils/getLocalizeString"
import css from './CodeInput.module.scss'

const CodeInput: FC = () => {
  const {errors, setFieldValue, setFieldTouched} = useFormikContext<any>()
  const [error, setError] = useState<any>()

  useEffect(() => {
    if ( errors ) setError(errors.code)
  }, [errors])

  return (
    <div className={css.CodeInput}>
      <ReactCodeInput
        name={'CodeInput'}
        className={css.CodeInput__Inputs}
        inputMode={'numeric'}
        type='number'
        fields={6}
        onChange={async (e) => {
          await setFieldValue('code', e)
          setFieldTouched('code', true, false)
        }}
      />

      {(errors && errors.code) && (
        <div className={css.CodeInput__Error}>
          <span>{getLocalizeString(error)}</span>
        </div>
      )}
    </div>
  )
}

export default CodeInput
