import React, { FC, createContext, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { checkAuth, logout } from './api'
import { TAppContext } from './types'
import redirect from './utils/redirect'
import Header from './components/Header'
import Auth from './components/Auth'
import Footer from './components/Footer'
import ProLayout from './components/ProLayout'

const appContext: any = createContext({})

// For dev test: 'pro' | 'default'
const devType: string = ''

export const useAppContext = () => {
  return useContext<TAppContext>(appContext)
}

const AppContext: FC = () => {
  const dispatch = useDispatch()
  const [lang, setLang] = useState<string>('')
  const [appType, setAppType] = useState<string>('')
  const langRedux = useSelector(({ reducer }: any) => reducer.lang)
  const showCaptcha = useSelector(({ reducer }: any) => reducer.showCaptcha)
  const baseUrl = useSelector(({ reducer }: any) => reducer.baseUrl)
  const isGuest = useSelector(({ reducer }: any) => reducer.isGuest)
  const langLocal = localStorage.getItem('lang')
  const showCaptchaLocal = localStorage.getItem('showCaptcha')
  const host = window.location.hostname
  const path = window.location.pathname

  useEffect(() => {
    if ( langLocal && langLocal !== langRedux )
      dispatch({ type: 'SET_LANG', payload: langLocal })
    else
      dispatch({ type: 'SET_LANG', payload: navigator.language })

    if ( showCaptchaLocal && showCaptchaLocal !== showCaptcha )
      dispatch({ type: 'SET_SHOW_CAPTCHA', payload: showCaptchaLocal })

    if ( host ) {
      if ( devType === 'default' || host.includes('.be') )
        setAppType('default')

      if ( devType === 'pro' || host.includes('.pro') )
        setAppType('pro')
    }
  }, [])

  useEffect(() => {
    if ( appType ) {
      dispatch({
        type: 'SET_BASE_URL',
        payload: appType === 'pro'
          ? process.env.REACT_APP_BASE_URI_PRO
          : process.env.REACT_APP_BASE_URI_BE
      })
    }
  }, [appType])

  useEffect(() => {
    if ( baseUrl ) {
      if ( path === '/logout' ) {
        logout().then((res) => {
          if ( res.revoked )
            window.location.href = '/login'
          else
            window.location.href = '/500'
        }).catch(() => {
          window.location.href = '/500'
        })
      } else {
        checkAuth().then(res => {
          if (!res.isGuest && res.access_token) {
            localStorage.setItem('access_token', res.access_token)
            localStorage.setItem('refresh_token', res.refresh_token)
          }
          dispatch({
            type: 'SET_IS_GUEST',
            payload: res.isGuest
          })
        })
      }
    }
  }, [baseUrl])

  useEffect(() => {
    if ( langRedux ) setLang(langRedux)
  }, [langRedux])

  useEffect(() => {
    if ( isGuest ) {
      if ( appType === 'default' )
        document.querySelector('body')!.className = 'default'

      if ( appType === 'pro' )
        document.querySelector('body')!.className = 'pro'
    }
  }, [isGuest])

  if ( isGuest === false ) {
    let access_token = localStorage.getItem('access_token')
    let refresh_token = localStorage.getItem('refresh_token')
    // @ts-ignore
    redirect(localStorage.getItem('token_app') || appType, access_token, refresh_token)
  }

  return (isGuest) && (
    <appContext.Provider
      value={{
        lang,
        appType
      }}
    >
      {appType === 'default' ? (
        <React.StrictMode>
          <BrowserRouter>
            <Header/>
            <Auth/>
          </BrowserRouter>

          <Footer/>
        </React.StrictMode>
      ) : appType === 'pro' && (
        <React.StrictMode>
          <BrowserRouter>
            <ProLayout>
              <Auth/>
            </ProLayout>
          </BrowserRouter>
        </React.StrictMode>
      )}
    </appContext.Provider>
  )
}

export default AppContext
