import {useState} from "react"
import {ValidationError} from "yup"

const usePassValidate = () => {
  const [passValidators, setPassValidators] = useState({})

  const validatePass = (err: ValidationError) => {
    setPassValidators(() => {
      const validators = err.inner.filter((e: ValidationError) => e.path === 'password')
      const passErrors: any = {}

      validators.forEach((v: ValidationError) => {
        if ( v.type === 'min' )
          passErrors.chars = true

        if ( v.type === 'matches' ) {
          if ( v.message === 'numbersError' )
            passErrors.nums = true

          if ( v.message === 'lettersError' )
            passErrors.lets = true
        }
      })

      return passErrors
    })
  }

  return {validatePass, passValidators, setPassValidators}
}

export default usePassValidate
