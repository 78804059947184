import {store} from '../redux/store'

/**
 * Function for translate localization.
 * @param {string} string - String to translate.
 * @param {string} vars - Variables to replace.
 */
export default (string: string = '', vars?: {[key: string]: string}): string => {
  const reduxStore: any = store.getState().reducer
  let resultStr = ''

  if ( reduxStore.lang === 'ru-RU' )
    if ( localization[string] )
      resultStr = localization[string]
    else
      resultStr = string
  else
    resultStr = string

  if ( vars && Object.keys(vars).length ) {
    Object.keys(vars).forEach(key => {
      resultStr = resultStr.replace(`{${key}}`, vars[key])
    })
  }

  return resultStr
}

const localization: { [key: string]: string } = {
  'Extension': 'Расширение',
  'Learn how to make money': 'Заработок без вложений',
  'to Webmaster': 'Веб-мастеру',
  'Log in': 'Войти',
  'Log in ': 'Вход',
  'Sign up': 'Регистрация',
  'Sign up ': 'Зарегистрироваться',
  'Sign in': 'Авторизоваться',
  'Log in with Surfe.be': 'Войти через Surfe.be',
  'Recover your password': 'Восстановить пароль',
  'Reset your password': 'Сбросить пароль',
  'Finish': 'Завершить',
  'Continue': 'Продолжить',
  'Close': 'Закрыть',
  'Terms Of Service': 'Условия сервиса',
  'Privacy Policy': 'Политика приватности',
  'Cookie Policy': 'Политика cookie',
  'Payment methods': 'Способы оплаты',
  'Webmasters': 'Веб-мастеру',
  'Sign in with': 'Войти с помощью',
  'Sign in with social networks': 'Войти с помощью соцсетей',
  'Invalid login or password': 'Неверный логин или пароль',
  'Field can not be empty': 'Поле не может быть пустым',
  'Please, solve the captcha': 'Пожалуйста, решите капчу',
  'Login or e-mail': 'Логин или e-mail',
  'Username should contain at least 3 characters.': 'Значение «Имя пользователя» должно содержать минимум 3 символа.',
  'Username is too long': 'Имя пользователя слишком длинное',
  'Username is invalid.': 'Значение «Имя пользователя» неверно.',
  'Email is not a valid email address.': 'Значение «Email» не является правильным email адресом.',
  'Password': 'Пароль',
  'Login': 'Логин',
  'Failed to login': 'Ошибка при входе на сайт',
  'Failed to register': 'Ошибка при регистрации',
  'Forgot password?': 'Забыли пароль?',
  'Page not found': 'Страница не найдена',
  'Required params: user id and token': 'Отсутствуют обязательные параметры: id пользователя и токен',
  'Already registered? Sign in!': 'Уже зарегистрированы? Авторизуйтесь!',
  'Make sure it\'s': 'Пароль должен содержать',
  'Password must contain': 'Пароль должен содержать',
  'at least 8 characters': 'хотя бы 8 символов',
  'including a number': 'включать цифры',
  'and a letter': 'и буквы',
  'I agree with': 'Я принимаю',
  'Weak': 'Слабый',
  'Normal': 'Нормальный',
  'Good': 'Хороший',
  'Strong': 'Надежный',
  'Help': 'Помощь',
  'Need help?': 'Нужна помощь?',
  'Write to': 'Пишите на',
  'Code must be exactly 6 characters': 'Код должен содержать 6 символов',
  'Code is invalid': 'Неверный код',
  'Something went wrong': 'Что-то пошло не так',
  'Two-factor authentication': 'Двухфакторная авторизация',
  'Haven\'t received the code?': 'Не пришел код?',
  'Scan to open bot': 'Отсканируй чтобы открыть бот',
  'Open <a href="tg://resolve?domain=Surfebe_bot" target="_blank">chat with bot</a> and send command <b>/code</b>.': 'Откройте <a href="tg://resolve?domain=Surfebe_bot" target="_blank">чат с ботом</a> и отправьте команду <b>/code</b>.',
  'You have activated two-factor authentication with {type}.': 'Вами была активирована двухфакторная авторизация через {type}.',
  'Enter 6-digit code from {type} message to enter profile': 'Введите 6-значный код из сообщения в {type}, чтобы войти в свой профиль',
  'If the code did not come, you must disable code confirmation and try again.': 'Если код не пришел, необходимо отключить подтверждение кодом и попробовать снова.',
  'To do this, send an email to <b>info@surfe.be</b> from your email address with the words "disable confirmation code".': 'Для этого, отправьте письмо на <b>info@surfe.be</b> с вашей почты со словами "отключить подтверждение кодом".',
  'Banner advertising placement service': 'Сервис размещения баннерной рекламы',
  'Password has been changed': 'Пароль был изменен',
  'Please contact us by writing at info@surfe.be': 'Пожалуйста, свяжитесь с нами, написав нам по адресу info@surfe.be',
  'We can\'t send you emails because your email service is rejecting them.': 'Мы не можем отправить вам письмо, потому что ваш почтовый сервис отклоняет их.',
  'Logged in with an unknown IP address. We sent a 6-digit code to your email, which you need to enter below to access your personal account.': 'Зафиксирован вход с неизвестного IP адреса. Мы отправили 6-значный код на ваш email, который нужно ввести ниже чтобы получить доступ в личный кабинет.',
  'To disable this IP verification, activate two-factor authentication on <a href="/profile/settings" target="_blank">Settings page</a> in any way you can.': 'Чтобы отключить данную проверку по IP при входе активируйте Двухфакторную авторизацию на странице <a href="/profile/settings" target="_blank">Настройки</a> любым доступным способом.',
  'An email has been sent with instructions for resetting your password': 'Вам отправлено письмо с инструкциями по смене пароля.',
  'Error accrued while sending email. Try again later.': 'Во время отправки письма произошла ошибка. Попробуйте позже.',
  'Error accrued while changing password. Try again later.': 'Во время смены пароля произошла ошибка. Попробуйте позже.',
  'An unexpected error has occurred. Please try again later': 'Произошла непредвиденная ошибка. Пожалуйста, попробуйте позже.'
}
