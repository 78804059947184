import React, {FC} from 'react'
import {useDispatch, useSelector} from "react-redux"
import cn from 'classnames'
import getLocalizeString from "../../utils/getLocalizeString"
import Lang from '../Lang'
import css from './MobileMenu.module.scss'

const MobileMenu: FC = () => {
  const dispatch = useDispatch()
  const isMenuOpen = useSelector(({reducer}: any) => reducer.isMenuOpen)

  return (
    <div
      className={cn([
        css.MobileMenu,
        {[css.MobileMenu_isOpen]: isMenuOpen}
      ])}
    >
      <div className={css.MobileMenu__Content}>
        <div className={css.MobileMenu__Header}>
          <div
            className={css.MobileMenu__Close}
            onClick={() => dispatch({type: 'SET_MENU_OPEN', payload: false})}
          >
            <img src="https://static.surfe.be/tpl/land-adv/img/close-line.svg" alt="ico"/>
          </div>

          <Lang/>
        </div>

        <nav className={css.MobileMenu__Nav}>
          <ul className={css.MobileMenu__NavTop}>
            <li>
              <a href="https://surfe.be/goal_ext" target="_blank">
                {getLocalizeString('Extension')}
              </a>
            </li>

            <li>
              <a href="https://surfe.be/earn/">
                {getLocalizeString('Learn how to make money')}
              </a>
            </li>

            <li>
              <a href="https://surfe.pro">
                {getLocalizeString('to Webmaster')}
              </a>
            </li>
          </ul>

          <ul className={css.MobileMenu__NavBottom}>
            <li>
              <a href="https://surfe.be/site/terms-of-service">
                {getLocalizeString('Terms Of Service')}
              </a>
            </li>

            <li>
              <a href="https://surfe.be/site/privacy-policy">
                {getLocalizeString('Privacy Policy')}
              </a>
            </li>

            <li>
              <a href="https://surfe.be/site/cookie-policy">
                {getLocalizeString('Cookie Policy')}
              </a>
            </li>

            <li>
              <a href="https://surfe.be/help/payment-methods">
                {getLocalizeString('Payment methods')}
              </a>
            </li>

            <li>
              <a href="https://surfe.pro/" target="_blank">
                {getLocalizeString('Webmasters')}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default MobileMenu
