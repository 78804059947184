import React, {FC, useEffect, useRef, useState} from 'react'
import cn from 'classnames'
import {Field, useFormikContext} from "formik"
import {useAppContext} from '../../AppContext'
import getLocalizeString from "../../utils/getLocalizeString"
import {TInput} from '../../types'
import TipMessage from "../TipMessage"
import Tooltip from "../Tooltip"
import css from './Input.module.scss'

const passLevels = ['Weak', 'Normal', 'Good', 'Strong']

const Input: FC<TInput> = (
  {
    type = 'text',
    name = '',
    placeholder,
    variant = '',
    tip,
    tooltip,
    passValidators,
    onChange
  }
) => {
  const {errors, touched, values, setFieldValue, setFieldTouched} = useFormikContext<any>()
  const {appType} = useAppContext()
  const [isError, setIsError] = useState<boolean>(false)
  const [isFocus, setIsFocus] = useState<boolean>(false)
  const [isFilled, setIsFilled] = useState(false)
  const [errorText, setErrorText] = useState<string>('')
  const [showPass, setShowPass] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(true)
  const [passError, setPassError] = useState<boolean>(false)
  const [passStrengthLevel, setPassStrengthLevel] = useState<number>(1)
  const [passStrengthText, setPassStrengthText] = useState<string>('Weak')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if ( passValidators ) {
      setPassStrengthLevel((prev) => {
        prev = 0

        if ( values[name] ) {
          (!passValidators.chars) && prev++
          (!passValidators.lets) && prev++
          (!passValidators.nums) && prev++
          (values[name].length > 11) && prev++
        }

        setPassStrengthText(passLevels[prev - 1])

        return prev
      })
    }
  }, [passValidators])

  useEffect(() => {
    if ( tip && variant === 'pass' )
      setShowError(false)
  }, [tip, variant])

  useEffect(() => {
    if ( tip && variant === 'pass' && appType === 'pro' && !values[name] )
      setPassError(true)
    else
      setPassError(false)
  }, [tip, variant, values[name]])

  useEffect(() => {
    if ( errors && errors[name] && touched[name] ) {
      setIsError(true)
      setErrorText(errors[name] as string)
    } else {
      setIsError(false)
      setErrorText('')
    }
  }, [errors, touched])

  useEffect(() => {
    if ( inputRef.current?.value )
      setIsFilled(true)
    else
      setIsFilled(false)
  }, [inputRef.current?.value])

  return (appType === 'pro') ? (
    <>
      <div
        className={cn([
          css.Input,
          css.Input_pro,
          {[css.Input_isError]: isError && (showError || passError)},
          {[css.Input_isHidden]: type === 'hidden'},
          {[css.Input_isFocus]: isFocus},
          {[css.Input_isFilled]: isFilled}
        ])}
      >
        <Field>
          {() => (
            <input
              id={name}
              name={name}
              ref={inputRef}
              type={showPass ? 'text' : type}
              onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                onChange && onChange(e)
                await setFieldValue(name, e.target.value.trim())
                setFieldTouched(name, true, false)
              }}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
              data-tip
              data-for={name + '-input-tooltip'}
              data-event={'focus'}
              data-event-off={'blur'}
              data-offset="{'top': 42}"
            />
          )}
        </Field>

        <label htmlFor={name}>{getLocalizeString(placeholder)}</label>

        {(variant === 'pass') && (
          <div
            className={cn([css.Input__Icon, css.Input__Icon_pass])}
            onClick={() => setShowPass(prev => !prev)}
          >
            {showPass ? (
              <svg className="closed-eye" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                <path
                  d="M19.604 2.562l-3.346 3.137c-1.27-.428-2.686-.699-4.243-.699-7.569 0-12.015 6.551-12.015 6.551s1.928 2.951 5.146 5.138l-2.911 2.909 1.414 1.414 17.37-17.035-1.415-1.415zm-6.016 5.779c-3.288-1.453-6.681 1.908-5.265 5.206l-1.726 1.707c-1.814-1.16-3.225-2.65-4.06-3.66 1.493-1.648 4.817-4.594 9.478-4.594.927 0 1.796.119 2.61.315l-1.037 1.026zm-2.883 7.431l5.09-4.993c1.017 3.111-2.003 6.067-5.09 4.993zm13.295-4.221s-4.252 7.449-11.985 7.449c-1.379 0-2.662-.291-3.851-.737l1.614-1.583c.715.193 1.458.32 2.237.32 4.791 0 8.104-3.527 9.504-5.364-.729-.822-1.956-1.99-3.587-2.952l1.489-1.46c2.982 1.9 4.579 4.327 4.579 4.327z"/>
              </svg>
            ) : (
              <svg className="open-eye" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                <path
                  d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.209 0-4 1.792-4 4 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.208-1.791-4-4-4z"/>
              </svg>
            )}
          </div>
        )}

        {(showError || passError) && (
          <div className={css.Input__Error}>
            <span>{getLocalizeString(errorText)}</span>
          </div>
        )}

        {tooltip && (
          <Tooltip
            id={name + '-input-tooltip'}
            place={'top'}
          >
            <div className={css.Input__PassTooltip}>
              <span>{getLocalizeString('Password must contain')}</span>

              <ul>
                <li>
                  {(!passValidators?.chars && values[name]) ? (
                    <img src={`${process.env.PUBLIC_URL}/checkbox-circle-green.svg`} alt="icon"/>
                  ) : (
                    <img src={`${process.env.PUBLIC_URL}/close-circle-grey.svg`} alt="icon"/>
                  )}

                  <span>{getLocalizeString('at least 8 characters')}</span>
                </li>

                <li>
                  {(!passValidators?.nums && values[name]) ? (
                    <img src={`${process.env.PUBLIC_URL}/checkbox-circle-green.svg`} alt="icon"/>
                  ) : (
                    <img src={`${process.env.PUBLIC_URL}/close-circle-grey.svg`} alt="icon"/>
                  )}

                  <span>{getLocalizeString('including a number')}</span>
                </li>

                <li>
                  {(!passValidators?.lets && values[name]) ? (
                    <img src={`${process.env.PUBLIC_URL}/checkbox-circle-green.svg`} alt="icon"/>
                  ) : (
                    <img src={`${process.env.PUBLIC_URL}/close-circle-grey.svg`} alt="icon"/>
                  )}

                  <span>{getLocalizeString('and a letter')}</span>
                </li>
              </ul>
            </div>
          </Tooltip>
        )}
      </div>

      {(tip && variant === 'pass' && values[name]) && (
        <div
          className={cn([
            css.Input__PassStrength,
            {[css.Input__PassStrength_bad]: passStrengthLevel < 2},
            {[css.Input__PassStrength_normal]: passStrengthLevel === 2},
            {[css.Input__PassStrength_good]: passStrengthLevel === 3},
            {[css.Input__PassStrength_strong]: passStrengthLevel === 4}
          ])}
        >
          <ul><li/><li/><li/><li/></ul>
          <span>{getLocalizeString(passStrengthText)}</span>
        </div>
      )}
    </>
  ) : (
    <div
      className={cn([
        css.Input,
        {[css.Input_isError]: isError && showError},
        {[css.Input_isHidden]: type === 'hidden'}
      ])}
    >
      <div className={css.Input__Icon}>
        {(variant === 'pass') && (
          <svg width="14" height="17" viewBox="0 0 14 17" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13.2222 5.44444H11.6667V4.66667C11.6667 2.08934 9.57733 0 7 0C4.42267 0 2.33333 2.08934 2.33333 4.66667V5.44444H0.777778C0.348223 5.44444 0 5.79267 0 6.22222V15.5556C0 15.9851 0.348223 16.3333 0.777778 16.3333H13.2222C13.6518 16.3333 14 15.9851 14 15.5556V6.22222C14 5.79267 13.6518 5.44444 13.2222 5.44444ZM1.55556 14.7778V7H12.4444V14.7778H1.55556ZM7.77778 10.1111V11.6667H6.22222V10.1111H7.77778ZM4.66667 10.1111H3.11111V11.6667H4.66667V10.1111ZM9.33333 11.6667V10.1111H10.8889V11.6667H9.33333ZM10.1111 4.66667V5.44444H3.88889V4.66667C3.88889 2.94845 5.28178 1.55556 7 1.55556C8.71822 1.55556 10.1111 2.94845 10.1111 4.66667Z"/>
          </svg>
        )}

        {(variant === 'login') && (
          <svg width="14" height="19" viewBox="0 0 14 19" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7 10.5C4.09937 10.5 1.75 8.15063 1.75 5.25C1.75 2.34938 4.09937 0 7 0C9.90063 0 12.25 2.34938 12.25 5.25C12.25 8.15063 9.90063 10.5 7 10.5ZM0 18.375C0 14.509 3.13401 11.375 7 11.375C10.866 11.375 14 14.509 14 18.375H12.25C12.25 15.4755 9.8995 13.125 7 13.125C4.1005 13.125 1.75 15.4755 1.75 18.375H0ZM10.5 5.25C10.5 7.18375 8.93375 8.75 7 8.75C5.06625 8.75 3.5 7.18375 3.5 5.25C3.5 3.31625 5.06625 1.75 7 1.75C8.93375 1.75 10.5 3.31625 10.5 5.25Z"/>
          </svg>
        )}

        {(variant === 'email') && (
          <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0.874892 0.875H13.1249C13.5008 0.875 13.8054 1.1797 13.8054 1.55556V12.4444C13.8054 12.8203 13.5008 13.125 13.1249 13.125H0.874892C0.499031 13.125 0.194336 12.8203 0.194336 12.4444V1.55556C0.194336 1.1797 0.499031 0.875 0.874892 0.875ZM12.4443 3.75919L7.04889 8.59114L1.55545 3.74422V11.7639H12.4443V3.75919ZM7.04141 6.76997L1.90321 2.23611H12.1054L7.04141 6.76997Z"/>
          </svg>
        )}
      </div>

      <Field
        name={name}
        type={showPass ? 'text' : type}
        placeholder={getLocalizeString(placeholder)}
        onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
          onChange && onChange(e)
          await setFieldValue(name, e.target.value.trim())
          setFieldTouched(name, true, false)
        }}
      />

      {(variant === 'pass') && (
        <div
          className={cn([css.Input__Icon, css.Input__Icon_pass])}
          onClick={() => setShowPass(prev => !prev)}
        >
          {showPass ? (
            <svg className="closed-eye" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
              <path
                d="M19.604 2.562l-3.346 3.137c-1.27-.428-2.686-.699-4.243-.699-7.569 0-12.015 6.551-12.015 6.551s1.928 2.951 5.146 5.138l-2.911 2.909 1.414 1.414 17.37-17.035-1.415-1.415zm-6.016 5.779c-3.288-1.453-6.681 1.908-5.265 5.206l-1.726 1.707c-1.814-1.16-3.225-2.65-4.06-3.66 1.493-1.648 4.817-4.594 9.478-4.594.927 0 1.796.119 2.61.315l-1.037 1.026zm-2.883 7.431l5.09-4.993c1.017 3.111-2.003 6.067-5.09 4.993zm13.295-4.221s-4.252 7.449-11.985 7.449c-1.379 0-2.662-.291-3.851-.737l1.614-1.583c.715.193 1.458.32 2.237.32 4.791 0 8.104-3.527 9.504-5.364-.729-.822-1.956-1.99-3.587-2.952l1.489-1.46c2.982 1.9 4.579 4.327 4.579 4.327z"/>
            </svg>
          ) : (
            <svg className="open-eye" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
              <path
                d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 3c-2.209 0-4 1.792-4 4 0 2.209 1.791 4 4 4s4-1.791 4-4c0-2.208-1.791-4-4-4z"/>
            </svg>
          )}
        </div>
      )}

      {(errorText && showError) && (
        <div className={css.Input__Error}>
          <span>{getLocalizeString(errorText)}</span>
        </div>
      )}

      {tip && (
        <TipMessage
          chars={passValidators?.chars || ''}
          nums={passValidators?.nums || ''}
          lets={passValidators?.lets || ''}
          touched={touched[name]}
        />
      )}
    </div>
  )
}

export default Input
