import React, {FC} from 'react'
import MetaTags from "react-meta-tags"
import * as Yup from "yup"
import {useAppContext} from "../../AppContext"
import useFormActions from "../../hooks/useFormActions"
import getLocalizeString from "../../utils/getLocalizeString"
import Input from "../Input"
import Button from "../Button"
import Form from "../Form"

const Request: FC = () => {
  const {submitRequest} = useFormActions()
  const {appType} = useAppContext()

  return (
    <>
      <MetaTags>
        <title>{getLocalizeString('Recover your password')}</title>
      </MetaTags>

      <Form
        title={'Recover your password'}
        initFields={{email: ''}}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required('Field can not be empty')
            .email('Email is not a valid email address.'),
        })}
        onSubmit={(values, actions) => {
          submitRequest(values)
            .finally(() => actions?.setSubmitting(false))
        }}
      >
        {({isSubmitting}) => (
          <>
            <div className={'form-block'}>
              <Input
                name={'email'}
                placeholder={'Email'}
                variant={'email'}
              />
            </div>

            <div className={'form-block'}>
              <Button
                submit
                text={'Continue'}
                type={'blue'}
                isLoading={isSubmitting}
                size={(appType === 'pro') ? 'big' : ''}
              />
            </div>
          </>
        )}
      </Form>
    </>
  )
}

export default Request
