import React, { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import MetaTags from 'react-meta-tags'
import * as Yup from 'yup'
import { useAppContext } from '../../AppContext'
import useValidatedFields from '../../hooks/useValidatedFields'
import useFormActions from '../../hooks/useFormActions'
import getLocalizeString from '../../utils/getLocalizeString'
import Form from '../Form'
import Input from '../Input'
import Captcha from '../Captcha'
import Button from '../Button'

const Login: FC = () => {
  const dispatch = useDispatch()
  const initFields = {
    login: '',
    password: ''
  }
  const initValidators = {
    login: Yup.string()
      .required('Field can not be empty'),
    password: Yup.string()
      .required('Field can not be empty')
  }
  const { formFields, formValidators } = useValidatedFields(initFields, initValidators)
  const { submitLogin, requestCode } = useFormActions()
  const { appType } = useAppContext()
  const showCaptcha = useSelector(({ reducer }: any) => reducer.showCaptcha)



  useEffect(() => {
    let search = new URLSearchParams(window.location.search);
    let error = search.get('error')
    if (error) {
      dispatch({
        type: 'SET_SHOW_POPUP',
        payload: {
          isOpen: true,
          type: 'fail',
          text: decodeURI(error).replace(/[\u00A0-\u9999<>\&]/gim,
            (i) => ('&#' + i.charCodeAt(0) + ';')),
        }
      })
    } else {
      if (search.get('cross-auth')) {
        requestCode()
      }
    }

  },[]);

  return (
    <>
      <MetaTags>
        <title>{getLocalizeString('Sign in')}</title>
      </MetaTags>

      <Form
        title={'Log in '}
        withSocial
        initFields={formFields}
        validationSchema={Yup.object().shape(formValidators)}
        nav={[
          {
            link: '/login',
            title: 'Log in '
          },
          {
            link: '/register',
            title: 'Sign up'
          }
        ]}
        onSubmit={(values, actions) => {
          submitLogin(values, actions)
        }}
      >
        {({ isSubmitting }) => (
          <>
            <div className={'form-block'}>
              <Input
                name={'login'}
                placeholder={'Login or e-mail'}
                variant={'login'}
              />
            </div>

            <div className={'form-block'}>
              <Input
                name={'password'}
                type={'password'}
                placeholder={'Password'}
                variant={'pass'}
              />
            </div>

            <div className="forgot-password">
              <Link to={'/forgot'}>
                {getLocalizeString('Forgot password?')}
              </Link>
            </div>

            {showCaptcha && <Captcha/>}

            <div className={'form-block'}>
              <Button
                text={'Log in'}
                type={'blue'}
                submit
                size={(appType === 'pro') ? 'big' : ''}
                isLoading={isSubmitting}
              />
            </div>

            {(appType === 'default') && (
              <div className={'form-block'}>
                <Button
                  text={'Sign up'}
                  type={'white'}
                  link={'/register'}
                />
              </div>
            )}
          </>
        )}
      </Form>
    </>
  )
}

export default Login
