import React, {FC} from 'react'
import ReCAPTCHA from "react-google-recaptcha"
import {useFormikContext} from "formik"
import getLocalizeString from "../../utils/getLocalizeString"
import Input from "../Input"
import css from './Captcha.module.scss'

const Captcha: FC<{onChange?: () => void}> = ({onChange}) => {
  const {setFieldValue} = useFormikContext<any>()

  return (
    <div className={css.Captcha}>
      <ReCAPTCHA
        sitekey={
          (process.env.NODE_ENV === 'development')
            ? process.env.REACT_APP_CAPTCHA_KEY_DEV || ''
            : process.env.REACT_APP_CAPTCHA_KEY || ''
        }
        onChange={(value: any) => {
          onChange && onChange()
          setFieldValue('captcha', value)
        }}
      />

      <div className={css.Captcha__Message}>
        <span>{getLocalizeString('Please, solve the captcha')}</span>
      </div>

      <Input
        name={'captcha'}
        type={'hidden'}
      />
    </div>
  )
}

export default Captcha
