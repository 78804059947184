import {applyMiddleware, combineReducers, createStore} from "redux"
import thunk from "redux-thunk"
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly'
import {reducer} from "./reducer"

export const store: any = createStore(
  combineReducers({reducer}),
  composeWithDevTools(
    applyMiddleware(thunk)
  )
)

