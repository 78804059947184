import React, {FC} from 'react'
import ReactTooltip, {TooltipProps} from "react-tooltip"
import css from './Tooltip.module.scss'

const Tooltip: FC<TooltipProps> = ({id, place, children}) => {
  return (
    <ReactTooltip
      className={css.Tooltip}
      effect={'solid'}
      {...{id, place}}
    >
      {children}
    </ReactTooltip>
  )
}

export default Tooltip
