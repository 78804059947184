import React, {FC, useState} from 'react'
import {NavLink, useLocation} from "react-router-dom"
import {Formik, Form as FormikForm} from 'formik'
import {getSurfeToken} from '../../api'
import getLocalizeString from "../../utils/getLocalizeString"
import {TForm} from "../../types"
import {useAppContext} from "../../AppContext"
import Button from "../Button"
import css from './Form.module.scss'

const Form: FC<TForm> = ({title, withSocial, initFields, validationSchema, nav, validate, onSubmit, children}) => {
  const {appType} = useAppContext()
  const {pathname} = useLocation()
  const [surfeLoading, setSurfeLoading] = useState<boolean>(false)

  return (
    <div className={css.Form}>
      {(appType === 'pro' && nav) ? (
        <div className={css.Form__Nav}>
          {nav.map((n, i) => (
            <NavLink
              key={'nav' + i}
              to={n.link}
              className={({isActive}) =>
                isActive ? css.isActive : ''
              }
            >
              <span>{getLocalizeString(n.title)}</span>
            </NavLink>
          ))}
        </div>
      ) : (
        <div className={css.Form__Title}>
          {(pathname !== '/login' && pathname !== '/register') && (
            <NavLink
              to={'/login'}
              className={css.Form__Arr}
            >
              <svg width="16" height="16" viewBox="0 0 16 16">
                <path
                  d="M3.828 6.99998H16V8.99998H3.828L9.192 14.364L7.778 15.778L0 7.99998L7.778 0.221985L9.192 1.63598L3.828 6.99998Z"/>
              </svg>
            </NavLink>
          )}

          <span>{getLocalizeString(title)}</span>
        </div>
      )}

      <Formik
        initialValues={initFields}
        validationSchema={validationSchema}
        validate={validate}
        onSubmit={(values, actions) => {
          onSubmit && onSubmit(values, actions)
        }}
      >
        {(props) => (
          <FormikForm
            style={{paddingBottom: (appType === 'pro' && !withSocial) ? '48px' : '0'}}
          >
            {children(props)}
          </FormikForm>
        )}
      </Formik>

      {withSocial && (
        <div className={css.Form__Social}>
          {(appType === 'pro' && pathname === '/login') && (
            <div className={'form-block'}>
              <Button
                text={'Log in with Surfe.be'}
                icon={`<img src="${process.env.PUBLIC_URL}/surfebe.svg" alt="ico">`}
                type={'darkBluePro'}
                size={'medium'}
                isLoading={surfeLoading}
                onClick={() => {
                  setSurfeLoading(true)
                  getSurfeToken()
                    .then(res => {
                      if ( res.token )
                        window.location.href = `https://surfe.be/site/login-token?key=${res.token}`
                    })
                    .finally(() => setSurfeLoading(false))
                }}
              />
            </div>
          )}

          {(appType !== 'pro') && (
            <div className={css.Form__SocialTitle}>
              <span>{getLocalizeString('Sign in with social networks')}</span>
            </div>
          )}

          {(appType === 'default') ? (
            <ul>
              <li>
                <a href="https://surfe.be/user/auth?authclient=vkontakte">
                  <img src="https://static.surfe.be/tpl/land-adv/img/vk.svg" alt="ico"/>
                </a>
              </li>

              <li>
                <a href="https://surfe.be/user/auth?authclient=google">
                  <img src="https://static.surfe.be/tpl/land-adv/img/google.svg" alt="ico"/>
                </a>
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <a href="https://surfe.pro/user/auth?authclient=google">
                  <img src={`${process.env.PUBLIC_URL}/google.svg`} alt="ico"/>
                </a>
              </li>

              <li>
                <a href="https://surfe.pro/user/auth?authclient=vkontakte">
                  <img src={`${process.env.PUBLIC_URL}/vk.svg`} alt="ico"/>
                </a>
              </li>
            </ul>
          )}
        </div>
      )}
    </div>
  )
}

export default Form
