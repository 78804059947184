import React, {FC} from 'react'
import { Link } from "react-router-dom"
import {useDispatch} from "react-redux"
import getLocalizeString from '../../utils/getLocalizeString'
import Button from '../Button'
import Lang from '../Lang'
import MobileMenu from '../MobileMenu'
import css from './Header.module.scss'

const Header: FC = () => {
  const dispatch = useDispatch()

  return (
    <header className={css.Header}>
      <div className={css.Header__Container}>
        <div
          className={css.Header__MenuBtn}
          onClick={() => dispatch({type: 'SET_MENU_OPEN', payload: true})}
        >
          <img src="https://static.surfe.be/tpl/land-adv/img/burger.svg" alt="ico" />
        </div>

        <div className={css.Header__Nav}>
          <ul>
            <li>
              <a href={"https://surfe.be/goal_ext"} target="_blank">
                {getLocalizeString('Extension')}
              </a>
            </li>

            <li>
              <a href={"https://surfe.be/earn/"}>
                {getLocalizeString('Learn how to make money')}
              </a>
            </li>

            <li>
              <a href="https://surfe.pro">
                {getLocalizeString('to Webmaster')}
              </a>
            </li>
          </ul>
        </div>

        <MobileMenu/>

        <div className={css.Header__Logo}>
          <a href="https://surfe.be">
            <img src="https://static.surfe.be/images/LOGO-new-full-white.png" alt="logo" />
          </a>
        </div>

        <div className={css.Header__Right}>
          <Lang />

          <Link to={"/login"} className={css.Header__Login}>
            <svg width="14" height="16" viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M3.5 14V10.25H2V14.75C2 15.1642 2.33579 15.5 2.75 15.5H13.25C13.6642 15.5 14 15.1642 14 14.75V1.25C14 0.835786 13.6642 0.5 13.25 0.5H2.75C2.33579 0.5 2 0.835786 2 1.25V5.75H3.5V2H12.5V14H3.5ZM6.5 5V7.25H0.5V8.75H6.5V11L10.25 8L6.5 5Z" />
            </svg>

            <span>{getLocalizeString('Log in')}</span>
          </Link>

          <Button text={'Sign up'} link={'/register'} type={'header'}/>
        </div>
      </div>
    </header>
  )
}

export default Header
