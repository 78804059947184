import React, { FC, useEffect, useState } from 'react'
import cn from 'classnames'
import { Formik, Form as FormikForm } from 'formik'
import getLocalizeString from '../../utils/getLocalizeString'
import { TForm2Auth } from '../../types'
import { useAppContext } from '../../AppContext'
import css from '../Form/Form.module.scss'

const Form2Auth: FC<TForm2Auth> = ({ title, initFields, validationSchema, type, onSubmit, children }) => {
  const [formattedType, setFormattedType] = useState<string>('')
  const [text, setText] = useState<string>('')
  const { appType, lang } = useAppContext()

  useEffect(() => {
    if ( type ) {
      switch ( type ) {
        case 'telegram':
          setFormattedType('Telegram')
          break
        case 'google':
          setFormattedType('Google Authentificator')
          break
      }
    }
  }, [type])

  useEffect(() => {
    if ( formattedType ) {
      setText(() => {
        return `
        ${getLocalizeString(
          'You have activated two-factor authentication with {type}.',
          { type: `<b>${formattedType}</b>` })
        }      
        ${getLocalizeString(
          'Enter 6-digit code from {type} message to enter profile',
          { type: `<b>${formattedType}</b>` })
        }`
      })
    }
  }, [formattedType, lang])

  return (
    <div className={cn([css.Form, css.Form_2fa])}>
      <div className={css.Form__Image}>
        <img src={`${process.env.PUBLIC_URL}/2fa.png`} alt="2fa"/>
      </div>

      <div className={css.Form__Title}>
        <span>{getLocalizeString(title)}</span>
      </div>

      {(type === 'mail') ? (
        <div className={cn([css.Form__Text, css.Form__Text_mail])}>
          <div className={css.Form__TextWarn} style={{display: 'none'}}>
            <img src={`${process.env.PUBLIC_URL}/alert.svg`} alt="ico"/>

            <p>
              {getLocalizeString('We can\'t send you emails because your email service is rejecting them.')}
              &nbsp;
              {getLocalizeString('Please contact us by writing at info@surfe.be')}
            </p>
          </div>

          <p>
            {getLocalizeString('Logged in with an unknown IP address. We sent a 6-digit code to your email, which you need to enter below to access your personal account.')}
          </p>

          <p>
            <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" fill="#777777"
                    d="M0.333496 7C0.333496 10.682 3.31816 13.6667 7.00016 13.6667C10.6822 13.6667 13.6668 10.682 13.6668 7C13.6668 3.318 10.6822 0.333332 7.00016 0.333332C3.31816 0.333332 0.333496 3.318 0.333496 7ZM12.3335 7C12.3335 9.94552 9.94568 12.3333 7.00016 12.3333C4.05464 12.3333 1.66683 9.94552 1.66683 7C1.66683 4.05448 4.05464 1.66667 7.00016 1.66667C9.94568 1.66667 12.3335 4.05448 12.3335 7ZM7.66683 3.66667V5H6.3335V3.66667H7.66683ZM7.66683 10.3333V6.33333H6.3335V10.3333H7.66683Z"/>
            </svg>

            <i
              style={{ color: '#777' }}
              dangerouslySetInnerHTML={{ __html: `${getLocalizeString('To disable this IP verification, activate two-factor authentication on <a href="/profile/settings" target="_blank">Settings page</a> in any way you can.')}` }}
            />
          </p>
        </div>
      ) : (
        <div
          className={css.Form__Text}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}

      <Formik
        initialValues={initFields}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          onSubmit && onSubmit(values, actions)
        }}
      >
        {(props) => (
          <FormikForm
            style={{ paddingBottom: (appType === 'pro') ? '48px' : '0' }}
          >
            {children(props)}
          </FormikForm>
        )}
      </Formik>
    </div>
  )
}

export default Form2Auth
