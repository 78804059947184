import React, {FC} from 'react'
import Lang from "../Lang"
import css from './ProLayout.module.scss'
import getLocalizeString from "../../utils/getLocalizeString"

const ProLayout: FC = ({children}) => {
  return (
    <div className={css.ProLayout}>
      <div className={css.ProLayout__Logo}>
        <a href="/">
          <img src="/react-auth/header-logo.svg" alt="logo"/>
        </a>

        <Lang />
      </div>

      <div className={css.ProLayout__Container}>
        {children}
      </div>

      <div className={css.ProLayout__Help}>
        {getLocalizeString('Need help?')}&nbsp;
        {getLocalizeString('Write to')}&nbsp;
        <a href="mailto:info@surfe.pro" title="help">
          info@surfe.pro
        </a>
      </div>
    </div>
  )
}

export default ProLayout
