import React, {FC} from 'react'
import {TTipMessage} from "../../types"
import getLocalizeString from "../../utils/getLocalizeString"
import css from './TipMessage.module.scss'

const TipMessage: FC<TTipMessage> = ({chars, nums, lets, touched}) => {
  const setClass = (value: boolean | string) => {
    if ( touched ) {
      return (value)
        ? css.TipMessage__Red
        : css.TipMessage__Green
    }
  }

  return (
    <div className={css.TipMessage}>
      {getLocalizeString('Make sure it\'s')}&nbsp;

      <span className={setClass(chars)}>
        {getLocalizeString('at least 8 characters')}
        &nbsp;
      </span>

      <span className={setClass(nums)}>
        {getLocalizeString('including a number')}
        &nbsp;
      </span>

      <span className={setClass(lets)}>
        {getLocalizeString('and a letter')}
      </span>.
    </div>
  )
}

export default TipMessage
