import {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import * as Yup from "yup"

const useValidatedFields = (fields: any, validators: any) => {
  const [formFields, setFormFields] = useState<any>(() => fields)
  const [formValidators, setFormValidators] = useState<any>(() => validators)
  const showCaptcha = useSelector(({reducer}: any) => reducer.showCaptcha)

  useEffect(() => {
    if ( showCaptcha ) {
      setFormFields((prev: any) => ({...prev, captcha: ''}))
      setFormValidators((prev: any) => ({
        ...prev,
        captcha: Yup.string()
          .required()
      }))
    }
  }, [showCaptcha])

  return {formFields, formValidators}
}

export default useValidatedFields
