import React from 'react'
import getLocalizeString from '../../utils/getLocalizeString'
import Button from '../Button'
import css from './Error.module.scss'

const Page500 = () => {
  return (
    <>
      <section className={css.Error}>
        <img src="/react-auth/500.svg" alt="error 500"/>

        <div className={css.Error__Title}>
          <span>{getLocalizeString('Something went wrong')}</span>
        </div>

        <div className={css.Error__Buttons}>
          <Button
            text={getLocalizeString('Log in')}
            size={'big'}
            link={'/login'}
          />

          <Button
            text={getLocalizeString('Sign up ')}
            size={'big'}
            link={'/register'}
          />
        </div>
      </section>
    </>
  )
}

export default Page500
