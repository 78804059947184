import React, {FC} from 'react'
import getLocalizeString from "../../utils/getLocalizeString"
import {TPopup} from "../../types"
import Button from "../Button"
import css from './Popup.module.scss'

const Message: FC<TPopup> = ({type, title, text, actions, onClose}) => {
  return (
    <div className={css.PopupMessage}>
      {type && (
        <div className={css.PopupMessage__Icon}>
          <img src={`${process.env.PUBLIC_URL}/ico-${type}.svg`} alt="ico"/>
        </div>
      )}

      {title && (
        <div className={css.PopupMessage__Title}>
          <span dangerouslySetInnerHTML={{__html: getLocalizeString(title)}}/>
        </div>
      )}

      {text && (
        <div className={css.PopupMessage__Text}>
          <span>{getLocalizeString(text || '')}</span>
        </div>
      )}

      {actions ? (
        <div
          className={css.PopupMessage__Buttons}
          key={'btnRow'}
        >
          {actions.map((action: any, i: number) => (
            <Button
              key={'msgSubButton'+i}
              text={action.text}
              link={action.link}
              onClick={() => {
                onClose && onClose()
              }}
            />
          ))}
        </div>
      ) : (
        <Button
          text={'Close'}
          onClick={() => {
            onClose && onClose()
          }}
        />
      )}
    </div>
  )
}

export default Message
