import React, {FC, useEffect} from 'react'
import {useParams} from "react-router-dom"
import MetaTags from "react-meta-tags"
import {useDispatch} from "react-redux"
import * as Yup from "yup"
import {ValidationError} from "yup"
import {useAppContext} from "../../AppContext"
import getLocalizeString from "../../utils/getLocalizeString"
import usePassValidate from '../../hooks/usePassValidate'
import useFormActions from "../../hooks/useFormActions"
import Input from "../Input"
import Button from "../Button"
import Form from "../Form"

const Recover: FC = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const {validatePass, passValidators, setPassValidators} = usePassValidate()
  const {submitReset} = useFormActions()
  const {appType} = useAppContext()

  useEffect(() => {
    if ( !params.uid || !params.token ) {
      dispatch({
        type: 'SET_SHOW_POPUP',
        payload: {
          isOpen: true,
          type: 'fail',
          text: 'Required params: user id and token',
        }
      })
    }
  }, [params])

  const validatePassword = async (values: any) => {
    const errors: any = {}
    const schema = Yup.object().shape({
      password: Yup.string()
        .required()
        .min(8)
        .matches(/(?=.*\d).*$/, 'numbersError')
        .matches(/(?=.*[A-z]).*$/, 'lettersError'),
    })

    await schema.validate(values, {abortEarly: false})
      .then(() => setPassValidators({}))
      .catch((err) => {
        validatePass(err)
        errors.password = err.inner.find((e: ValidationError) => e.path === 'password')?.message
      })

    return errors
  }

  return (
    <>
      <MetaTags>
        <title>{getLocalizeString('Reset your password')}</title>
      </MetaTags>

      {(params.uid && params.token) && (
        <Form
          title={'Reset your password'}
          initFields={{password: ''}}
          validate={(values: any) => validatePassword(values)}
          onSubmit={(values, actions) => {
            submitReset(values, params.uid, params.token)
              .finally(() => actions?.setSubmitting(false))
          }}
        >
          {({isSubmitting}) => (
            <>
              <div className={'form-block'}>
                <Input
                  name={'password'}
                  placeholder={'Password'}
                  variant={'pass'}
                  type={'password'}
                  passValidators={passValidators}
                  tip
                />
              </div>

              <div className={'form-block'}>
                <Button
                  submit
                  text={'Finish'}
                  type={'blue'}
                  isLoading={isSubmitting}
                  size={(appType === 'pro') ? 'big' : ''}
                />
              </div>
            </>
          )}
        </Form>
      )}
    </>
  )
}

export default Recover
