import React, { FC, useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { COMMON_ERROR } from '../../constants'
import { codeCheck, getToken, codeSend } from '../../api'
import getLocalizeString from '../../utils/getLocalizeString'
import redirect from '../../utils/redirect'
import { useAppContext } from '../../AppContext'
import Form2Auth from '../Form2Auth'
import Button from '../Button'
import CodeInput from '../CodeInput'
import css from '../Form/Form.module.scss'

const formFields = { code: '' }
const formValidators = {
  code: Yup.string()
    .required('Field can not be empty')
    .length(6, 'Code must be exactly 6 characters')
}

const Login2Auth: FC = () => {
  const dispatch = useDispatch()
  const [type, setType] = useState<string>('')
  const [showDesc, setShowDesc] = useState<boolean>(false)
  const [isTimerEnd, setIsTimerEnd] = useState<boolean>(false)
  const { appType } = useAppContext()
  const { authType } = useParams()

  useEffect(() => {
    setTimeout(() => {
      setIsTimerEnd(true)
    }, 300000) // 5 min
  }, [])

  useEffect(() => {
    if ( authType ) {
      if ( authType === 'ta' ) setType('telegram')
      if ( authType === 'ma' ) setType('mail')
      if ( authType === 'ga' ) setType('google')
    }
  }, [authType])

  return (
    <>
      <MetaTags>
        <title>{getLocalizeString('Sign in')}</title>
      </MetaTags>

      <Form2Auth
        title={(appType === 'pro') ? 'Log in ' : 'Two-factor authentication'}
        initFields={formFields}
        validationSchema={Yup.object().shape(formValidators)}
        type={type}
        onSubmit={(values, actions) => {
          codeCheck(values.code)
            .then(async (res) => {
              if ( res.error )
                throw new Error(COMMON_ERROR)

              if ( res.status ) {
                await getToken({
                  grantType: 'authorization_code',
                  clientId: (appType === 'pro') ? 'surfepro' : 'surfebe',
                  redirectUri: ''
                })
                  .then((res) => {
                    if ( res.authorized ) {
                      dispatch({ type: 'SET_SHOW_CAPTCHA', payload: false })
                      localStorage.setItem('showCaptcha', '')
                      localStorage.setItem('token_app', appType)
                      localStorage.setItem('access_token', res.access_token)
                      localStorage.setItem('refresh_token', res.refresh_token)
                      redirect(appType, res.access_token, res.refresh_token)
                    }

                    if ( res.error )
                      dispatch({
                        type: 'SET_SHOW_POPUP',
                        payload: {
                          isOpen: true,
                          type: 'fail',
                          text: COMMON_ERROR,
                        }
                      })
                  })
                  .catch((e) => {
                    dispatch({
                      type: 'SET_SHOW_POPUP',
                      payload: {
                        isOpen: true,
                        type: 'fail',
                        text: e.message,
                      }
                    })
                  })
              }

              if ( res.errors )
                actions?.setFieldError('code', res.errors.code)
            })
            .catch((e) => {
              dispatch({
                type: 'SET_SHOW_POPUP',
                payload: {
                  isOpen: true,
                  type: 'fail',
                  text: e.message,
                }
              })
            })
            .finally(() => actions?.setSubmitting(false))
        }}
      >
        {({ isSubmitting }) => (
          <>
            <div className={'form-block'}>
              <CodeInput/>
            </div>

            <Button
              text={'Log in'}
              type={'blue'}
              submit
              size={(appType === 'pro') ? 'big' : 'medium'}
              isLoading={isSubmitting}
            />

            {(appType === 'default') && (
              <div style={{ marginTop: '16px' }}>
                {(authType === 'ta') && (
                  <>
                    <div
                      className={css.Form__Link}
                      style={{ display: !showDesc ? 'block' : 'none' }}
                    >
                      <span onClick={() => setShowDesc(true)}>
                        {getLocalizeString('Haven\'t received the code?')}
                      </span>
                    </div>

                    <div
                      className={css.Form__CodeDescription}
                      style={{ display: showDesc ? 'block' : 'none' }}
                    >
                      <div className={css.Form__CodeImage}>
                        <img src={`${process.env.PUBLIC_URL}/qr-surfebe_bot.png`} width="100%" alt="img"/>

                        <span>{getLocalizeString('Scan to open bot')}</span>
                      </div>

                      <div className={css.Form__CodeStep}>
                        <span>1</span>

                        <p
                          dangerouslySetInnerHTML={{ __html: `${getLocalizeString('Open <a href="tg://resolve?domain=Surfebe_bot" target="_blank">chat with bot</a> and send command <b>/code</b>.')}` }}/>
                      </div>

                      <div className={css.Form__CodeStep}>
                        <span>2</span>

                        <p>
                          {getLocalizeString('If the code did not come, you must disable code confirmation and try again.')}
                          <br/>
                          <br/>
                          <span
                            dangerouslySetInnerHTML={{ __html: `${getLocalizeString('To do this, send an email to <b>info@surfe.be</b> from your email address with the words "disable confirmation code".')}` }}/>
                        </p>
                      </div>
                    </div>

                    <div className={css.Form__Link}>
                      <a href="https://surfe.be/site/ga">{getLocalizeString('Sign in with')} Google Authentication</a>
                    </div>
                  </>
                )}

                {(authType === 'ga') && (
                  <div className={css.Form__Link}>
                    <a href="https://surfe.be/site/ta">{getLocalizeString('Sign in with')} Telegram</a>
                  </div>
                )}

                {(authType === 'ma') && (
                  <div className={css.Form__MailBottom}>
                    <div>
                      <span>Если вы все еще не получили письмо, проверьте папку Спам.</span>
                    </div>

                    <div className={css.Form__MailTimer}>
                      {!isTimerEnd ? (
                        <span>Повторная отправка через 5 минут</span>
                      ) : (
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault()

                            codeSend()
                              .then((res) => {
                                if ( res.error ) {
                                  dispatch({
                                    type: 'SET_SHOW_POPUP',
                                    payload: {
                                      isOpen: true,
                                      type: 'fail',
                                      text: COMMON_ERROR,
                                    }
                                  })
                                }
                              })
                              .catch((e) => {
                                dispatch({
                                  type: 'SET_SHOW_POPUP',
                                  payload: {
                                    isOpen: true,
                                    type: 'fail',
                                    text: e.message,
                                  }
                                })
                              })
                          }}
                        >
                          Отправить снова
                        </a>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </Form2Auth>
    </>
  )
}

export default Login2Auth
