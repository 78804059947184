import { TLogin, TRegister, TReset, TToken, TValidateRegister } from './types'
import { store } from './redux/store'

const DISABLE_CAPTCHA = '1'
const isDev = process.env.NODE_ENV === 'development'

let serverApiUrl = ''
let lang = ''
let appType = ''

store.subscribe(() => {
  const redux: any = store.getState().reducer

  // if ( isDev )
  //   serverApiUrl = `${redux.baseUrl?.replace('s://', '://')}:${process.env.REACT_APP_SERVER_PORT}`
  // else
    serverApiUrl = `${redux.baseUrl}/api-auth` // react-auth backend

  lang = redux.lang
  appType = redux.baseUrl?.includes('.pro') ? 'pro' : 'be'
})

const getApiUrl = () => {
  return (appType === 'pro')
    ? process.env.REACT_APP_API_URI_PRO
    : process.env.REACT_APP_API_URI_BE
}

const getClientId = () => {
  return (appType === 'pro') ? 'surfepro' : 'surfebe'
}

const getRedirectUri = () => {
  return (appType === 'pro')
    ? process.env.REACT_APP_REDIRECT_URI_PRO
    : process.env.REACT_APP_REDIRECT_URI_BE
}

const fetchOptions: RequestInit = {
  method: 'POST',
  credentials: 'include',
  headers: {
    'content-type': 'application/json',
  }
}

export const userLogin = (
  {
    login,
    password,
    gRecaptchaResponse,
  }: TLogin
) => {
  return fetch(`${serverApiUrl}/login`, {
    ...fetchOptions,
    body: JSON.stringify({
      login,
      password,
      clientId: getClientId(),
      redirect: getRedirectUri(),
      'g-recaptcha-response': (isDev && DISABLE_CAPTCHA) ? '' : gRecaptchaResponse,
      'timezone_offset': new Date().getTimezoneOffset().toString(),
      'disable_captcha': isDev ? DISABLE_CAPTCHA : '0'
    })
  }).then(res => res.json())
}

export const userRegister = (
  {
    email,
    login,
    password,
    gRecaptchaResponse
  }: TRegister
) => {
  return fetch(`${serverApiUrl}/registration`, {
    ...fetchOptions,
    body: JSON.stringify({
      login,
      email,
      password,
      'g-recaptcha-response': (isDev && DISABLE_CAPTCHA) ? '' : gRecaptchaResponse,
      'disable_captcha': isDev ? DISABLE_CAPTCHA : '0',
    })
  }).then(res => res.json())
}

export const getToken = (
  {
    grantType,
    clientId
  }: TToken
) => {
  return fetch(`${serverApiUrl}/token`, {
    ...fetchOptions,
    body: JSON.stringify({
      grant_type: grantType,
      client_id: clientId,
      redirect_uri: getRedirectUri(),
      'disable_captcha': isDev ? DISABLE_CAPTCHA : '0'
    })
  }).then(res => res.json())
}

export const codeSend = () => {
  return fetch(`${serverApiUrl}/send/code`, {
    ...fetchOptions,
  }).then(res => res.json())
}

export const codeCheck = (code: string) => {
  return fetch(`${serverApiUrl}/check/code`, {
    ...fetchOptions,
    body: JSON.stringify({
      code_2fa: code,
      'disable_captcha': isDev ? DISABLE_CAPTCHA : '0',
    })
  }).then(res => res.json())
}

export const logout = () => {
  return fetch(`${serverApiUrl}/logout`, {
    ...fetchOptions,
    body: JSON.stringify({
      access_token: localStorage.getItem('access_token'),
      refresh_token: localStorage.getItem('refresh_token'),
    })
  }).then(res => res.json())
}

export const checkAuth = () => {
  return fetch(`${serverApiUrl}/check-auth`, {
    ...fetchOptions,
    body: JSON.stringify({
      token_app: localStorage.getItem('token_app'),
      access_token: localStorage.getItem('access_token'),
      refresh_token: localStorage.getItem('refresh_token'),
    })
  }).then(res => res.json())
}

export const userPassRequest = ({ email }: { email: string }) => {
  return fetch(`${serverApiUrl}/pass-request`, {
    ...fetchOptions,
    body: JSON.stringify({
      email,
      language: lang,
    })
  }).then(res => res.json())
}

export const userPassReset = ({ uid, key, password }: TReset) => {
  return fetch(`${serverApiUrl}/pass-reset`, {
    ...fetchOptions,
    body: JSON.stringify({
      uid,
      key,
      password,
      language: lang,
    })
  }).then(res => res.json())
}

export const validateRegisterForm = (
  {
    email,
    login,
    gRecaptchaResponse,
    language
  }: TValidateRegister
) => {
  return fetch(`${serverApiUrl}/reg-check`, {
    ...fetchOptions,
    body: JSON.stringify({
      username: login,
      email,
      language,
      'g-recaptcha-response': (isDev && DISABLE_CAPTCHA) ? '' : gRecaptchaResponse,
      'disable_captcha': isDev ? DISABLE_CAPTCHA : '0',
    })
  }).then(res => res.json())
}

export const getSurfeToken = () => {
  const apiUrl = getApiUrl()

  return fetch(`${apiUrl}/auth/login-surfebe`)
    .then(res => res.json())
}


