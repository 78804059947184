import React, {FC, useEffect, useRef, useState} from 'react'
import {useSelector, useDispatch} from "react-redux"
import {CSSTransition} from 'react-transition-group'
import Message from './Message'
import css from './Popup.module.scss'

const Popup: FC = () => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [options, setOptions] = useState<any>(null)
  const popupEl = useRef(null)
  const showPopup = useSelector(({reducer}: any) => reducer.showPopup)

  useEffect(() => {
    if ( showPopup ) {
      setIsOpen(showPopup.isOpen)

      if ( showPopup.isOpen ) {
        setOptions({
          type: showPopup.type,
          text: showPopup.text,
          actions: showPopup.actions
        })
      } else if ( !showPopup.isOpen && isOpen ) {
        setTimeout(() => {
          setOptions({
            type: showPopup.type,
            text: showPopup.text,
            actions: showPopup.actions
          })
        }, 300)
      }
    }
  }, [showPopup])

  const onClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    if ( e.target === popupEl.current ) {
      dispatch({
        type: 'SET_SHOW_POPUP',
        payload: {
          isOpen: false,
          type: '',
          text: ''
        }
      })
    }
  }

  const closePopup = () => {
    dispatch({
      type: 'SET_SHOW_POPUP',
      payload: {
        isOpen: false,
        type: '',
        text: ''
      }
    })
  }

  return (
    <CSSTransition
      in={isOpen}
      timeout={{exit: 300}}
      unmountOnExit
      classNames="fade"
    >
      <div className={css.Popup}
           onMouseDown={(e) => onClickHandler(e)}
           ref={popupEl}
      >
        <div className={css.Popup__Content}>
          <div className={css.Popup__Close}
               onClick={closePopup}
          >
            <img src={`${process.env.PUBLIC_URL}/close.svg`} alt="ico"/>
          </div>

          {options && (
            <Message
              type={options.type}
              title={options.text}
              actions={options.actions}
              onClose={() => closePopup()}
            />
          )}
        </div>
      </div>
    </CSSTransition>
  )
}

export default Popup
