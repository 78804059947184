export default function (appType: string, access_token?: string, refresh_token?: string, isReg?: boolean) {
  let params: Array<string> = [];
  if (access_token)
    params.push(`access_token=` + access_token);
  if (refresh_token)
    params.push(`refresh_token=` + refresh_token);
  if (isReg)
    params.push(`type=reg`);

  ( appType === 'pro' )
    ? window.location.href = process.env.REACT_APP_APP_URI_PRO + `?${params.join('&')}`
    : window.location.href = `/site/react-login?${params.join('&')}`
}
