import React, { FC, useEffect } from 'react'
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'
import {useSelector} from "react-redux"
import MetaTags from "react-meta-tags"
import getLocalizeString from "../../utils/getLocalizeString"
import Login from '../Login'
import Login2Auth from "../Login2Auth"
import Register from '../Register'
import Request from '../Request'
import Recover from '../Recover'
import Empty from '../Empty'
import Popup from "../Popup"
import Page404 from '../ErrorsPages/Page404'
import Page500 from '../ErrorsPages/Page500'
import css from './Auth.module.scss'

const Auth: FC = () => {
  const location = useLocation()
  const lang = useSelector(({reducer}: any) => reducer.lang)

  return (
    <div className={css.Auth}>
      <MetaTags>
        <meta property="og:title" content={`Surfe.be - ${getLocalizeString('Banner advertising placement service')}`}/>
        <meta property="og:url" content={`https://surfe.be${location.pathname}`}/>
        <meta property="og:image" content={`http://static.surfe.be/images/snippet/og_image_${lang.substring(0,2)}.jpg`}/>
      </MetaTags>

      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login/>}/>
        <Route path="/site/:authType" element={<Login2Auth/>} />
        <Route path="/register" element={<Register/>} />
        <Route path="/forgot" element={<Request/>} />
        <Route path="/user/recover" element={<Recover/>} >
          <Route path=":uid/:token" element={<Recover/>} />
        </Route>
        <Route path="/404" element={<Page404/>} />
        <Route path="/500" element={<Page500/>} />
        <Route path="*" element={<Empty/>} />
      </Routes>
      <Popup/>
    </div>
  )
}

export default Auth
