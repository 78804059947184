import { PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  lang: null,
  isMenuOpen: false,
  showCaptcha: false,
  baseUrl: null,
  isGuest: null,
  showPopup: {
    isOpen: false,
    type: '',
    text: '',
    actions: []
  }
}

export const reducer = (state = initialState, action: PayloadAction) => {
  switch (action.type) {
    case 'SET_LANG': {
      return {
        ...state,
        lang: action.payload
      }
    }
    case 'SET_MENU_OPEN': {
      return {
        ...state,
        isMenuOpen: action.payload
      }
    }
    case 'SET_SHOW_CAPTCHA': {
      return {
        ...state,
        showCaptcha: action.payload
      }
    }
    case 'SET_SHOW_POPUP': {
      return {
        ...state,
        showPopup: action.payload
      }
    }
    case 'SET_BASE_URL': {
      return {
        ...state,
        baseUrl: action.payload
      }
    }
    case 'SET_IS_GUEST': {
      return {
        ...state,
        isGuest: action.payload
      }
    }
    default:
      return state
  }
}
