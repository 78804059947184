import { FormikHelpers } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TLogin, TRegister } from '../types'
import {
  userLogin, userRegister, userPassRequest,
  userPassReset, validateRegisterForm, getToken, codeSend
} from '../api'
import redirect from '../utils/redirect'
import { COMMON_ERROR } from '../constants'
import { useAppContext } from '../AppContext'

const useFormActions = () => {
  const dispatch = useDispatch()
  const { appType, setUserData } = useAppContext()
  const lang = useSelector(({ reducer }: any) => reducer.lang)
  const navigate = useNavigate()

  const submitLogin = async (values: any, actions?: FormikHelpers<any>) => {
    const loginObj: TLogin = {
      login: values.login,
      password: values.password,
      gRecaptchaResponse: values.captcha || ''
    }

    try {
      const loginRes = await userLogin(loginObj)

      handleResult(loginRes, actions)

      if ( loginRes.code ) {
        requestCode(actions, !!values.email)
      }
    } catch ( e: any ) {
      dispatch({
        type: 'SET_SHOW_POPUP',
        payload: {
          isOpen: true,
          type: 'fail',
          text: e.message,
        }
      })
    }
  }

  const requestCode = async (actions?: FormikHelpers<any>, isReg?: boolean) => {

    const tokenRes = await getToken({
      grantType: 'authorization_code',
      clientId: (appType === 'pro') ? 'surfepro' : 'surfebe'
    })

    handleResult(tokenRes, actions)

    if ( tokenRes.twoAuth ) {
      const codeRes = await codeSend()

      handleResult(codeRes, actions)

      if ( codeRes.authorized && codeRes.type )
        navigate2auth(codeRes.type)
    }

    if ( tokenRes.authorized ) {
      dispatch({ type: 'SET_SHOW_CAPTCHA', payload: false })
      localStorage.setItem('showCaptcha', '')
      localStorage.setItem('token_app', appType)
      localStorage.setItem('access_token', tokenRes.access_token)
      localStorage.setItem('refresh_token', tokenRes.refresh_token)
      redirect(appType, tokenRes.access_token, tokenRes.refresh_token, isReg)
    }
  }

  const submitRegister = (values: any, actions?: FormikHelpers<any>) => {
    const regObj: TRegister = {
      login: values.login,
      email: values.email,
      password: values.password,
      gRecaptchaResponse: values.captcha || ''
    }

    return userRegister(regObj)
      .then((res) => {
        handleResult(res, actions)
        return res
      })
  }

  const submitRequest = (values: any) => {
    return userPassRequest({ email: values.email })
      .then((res) => {
        if ( handleErrorResponse(res) ) {
          return
        }

        dispatch({
          type: 'SET_SHOW_POPUP',
          payload: {
            isOpen: true,
            type: res.icon,
            text: res.status ? res.message : res.error,
          }
        })

        if ( res.status ) {
          navigate('/login')
        }
      })
      .catch((e) => {
        console.error(e)
        dispatch({
          type: 'SET_SHOW_POPUP',
          payload: {
            isOpen: true,
            type: 'fail',
            text: 'Error accrued while sending email. Try again later.',
          }
        })
      })
  }

  const submitReset = (values: any, uid: string = '', key: string = '') => {
    const resetObj = {
      uid,
      key,
      password: values.password
    }

    return userPassReset(resetObj)
      .then((res) => {
        if ( handleErrorResponse(res) ) {
          return
        }

        dispatch({
          type: 'SET_SHOW_POPUP',
          payload: {
            isOpen: true,
            type: res.icon,
            text: res.status ? res.message : res.error,
          }
        })

        if ( res.status ) {
          navigate('/login')
        }

      })
      .catch((e) => {
        console.error(e)
        dispatch({
          type: 'SET_SHOW_POPUP',
          payload: {
            isOpen: true,
            type: 'fail',
            text: 'Error accrued while changing password. Try again later.',
          }
        })
      })
  }

  const backendValidate = async (values: any, actions?: FormikHelpers<any>) => {
    const validateObj = {
      email: values.email,
      login: values.login,
      gRecaptchaResponse: values.captcha || '',
      language: lang
    }

    const validateRes = await validateRegisterForm(validateObj)

    handleResult(validateRes, actions)

    return validateRes
  }

  const handleResult = (result: any, actions?: FormikHelpers<any>) => {
    if ( result.captcha ) {
      dispatch({ type: 'SET_SHOW_CAPTCHA', payload: true })
      localStorage.setItem('showCaptcha', 'true')
      actions?.setSubmitting(false)

      return
    }

    if ( result.error ) {
      Object.keys(result.error).forEach((key) => {
        actions?.setFieldError(key, result.error[key])
      })

      actions?.setSubmitting(false)

      return
    }

    if ( result['stack-trace'] ) {
      actions?.setSubmitting(false)
      throw new Error(COMMON_ERROR)
    }
  }


  const handleErrorResponse = (resp: object) => {
    if ( 'stack-trace' in resp || !('icon' in resp) ) {
      dispatch({
        type: 'SET_SHOW_POPUP',
        payload: {
          isOpen: true,
          type: 'fail',
          text: 'message' in resp ? resp.message : 'Error accrued. Try again later.',
        }
      })
      return true;
    }

    return false;
  }

  const navigate2auth = (type: string) => {
    switch ( type ) {
      case 'telegram':
        navigate('/site/ta')
        break
      case 'google':
        navigate('/site/ga')
        break
      case 'mail':
        navigate('/site/ma')
        break
      case 'passed':
        let access_token = localStorage.getItem('access_token')
        let refresh_token = localStorage.getItem('refresh_token')
        // @ts-ignore
        redirect(appType, access_token, refresh_token)
        break
    }
  }

  return { submitLogin, requestCode, submitRegister, submitRequest, submitReset, backendValidate }
}

export default useFormActions
