import React, { FC } from 'react'
import getLocalizeString from "../../utils/getLocalizeString"
import css from './Footer.module.scss'

const Footer: FC = () => {
  return (
    <footer className={css.Footer}>
      <div className={css.Footer__Container}>
        <a href="https://surfe.be" className={css.Footer__Logo}>
          <img src="https://static.surfe.be/tpl/land-adv/img/footer_logo.png" alt="logo" />
        </a>

        <ul className={css.Footer__Nav}>
          <li>
            <a href={"https://surfe.be/site/terms-of-service"}>
              {getLocalizeString('Terms Of Service')}
            </a>
          </li>

          <li>
            <a href={"https://surfe.be/site/privacy-policy"}>
              {getLocalizeString('Privacy Policy')}
            </a>
          </li>

          <li>
            <a href={"https://surfe.be/site/cookie-policy"}>
              {getLocalizeString('Cookie Policy')}
            </a>
          </li>

          <li>
            <a href={"https://surfe.be/help/payment-methods"}>
              {getLocalizeString('Payment methods')}
            </a>
          </li>

          <li>
            <a href={"https://surfe.pro/"} target="_blank">
              {getLocalizeString('Webmasters')}
            </a>
          </li>

          <li>
            <a href={"https://surfe.be/help/popup/contacts"}>
              {getLocalizeString('Help')}
            </a>
          </li>
        </ul>

        <a href={"https://flatonica.com"} target="_blank" className={css.Footer__Created}>
          <span>Designed by</span>
          <img src="https://static.surfe.be/tpl/land-adv/img/logo-flatonica.svg" alt="logo" />
          <span>Flatonica</span>
        </a>
      </div>
    </footer>
  )
}

export default Footer
