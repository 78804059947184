import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MetaTags from 'react-meta-tags'
import { Field } from 'formik'
import * as Yup from 'yup'
import { ValidationError } from 'yup'
import cn from 'classnames'
import { useAppContext } from '../../AppContext'
import getLocalizeString from '../../utils/getLocalizeString'
import usePassValidate from '../../hooks/usePassValidate'
import useValidatedFields from '../../hooks/useValidatedFields'
import useFormActions from '../../hooks/useFormActions'
import Form from '../Form'
import Input from '../Input'
import Captcha from '../Captcha'
import Button from '../Button'

const Register: FC = () => {
  const initFields = {
    login: '',
    email: '',
    password: '',
    agreement: true
  }
  const initValidators = {
    login: Yup.string()
      .required('Field can not be empty')
      .min(3, 'Username should contain at least 3 characters.')
      .matches(/^[-a-zA-Z0-9_\.@]+$/, 'Username is invalid.'),
    email: Yup.string()
      .required('Field can not be empty')
      .email('Email is not a valid email address.'),
    password: Yup.string()
      .required('Field can not be empty')
      .min(8)
      .matches(/(?=.*\d).*$/, 'numbersError')
      .matches(/(?=.*[A-z]).*$/, 'lettersError'),
    agreement: Yup.boolean()
      .test(
        'is-agree',
        '',
        (value) => {
          setAgreeCheck(value)
          return !!value
        })
  }
  const dispatch = useDispatch()
  const { validatePass, passValidators, setPassValidators } = usePassValidate()
  const { formFields, formValidators } = useValidatedFields(initFields, initValidators)
  const { backendValidate, submitRegister, submitLogin } = useFormActions()
  const { appType } = useAppContext()
  const [agreeCheck, setAgreeCheck] = useState<boolean | undefined>(true)
  const showCaptcha = useSelector(({ reducer }: any) => reducer.showCaptcha)

  const registerValidation = async (values: any) => {
    const errors: any = {}
    const schema = Yup.object().shape(formValidators)

    await schema.validate(values, { abortEarly: false })
      .then(() => setPassValidators({}))
      .catch((err) => {
        validatePass(err)

        errors.login = err.inner.find((e: ValidationError) => e.path === 'login')?.message
        errors.email = err.inner.find((e: ValidationError) => e.path === 'email')?.message
        errors.password = err.inner.find((e: ValidationError) => e.path === 'password')?.message
      })

    return errors
  }

  return (
    <>
      <MetaTags>
        <title>{getLocalizeString('Sign up ')}</title>
      </MetaTags>

      <Form
        title={'Sign up '}
        withSocial
        initFields={formFields}
        validate={(values: any) => registerValidation(values)}
        nav={[
          {
            link: '/login',
            title: 'Log in '
          },
          {
            link: '/register',
            title: 'Sign up'
          }
        ]}
        onSubmit={(values, actions) => {
          backendValidate(values, actions)
            .then(res => {
              if ( !res.status ) {
                actions?.setFieldError('login', res.error.username)
                actions?.setFieldError('email', res.error.email)
                actions?.setSubmitting(false)
              } else {
                submitRegister(values, actions)
                  .then(res => {
                    if ( res.registered ) {
                      submitLogin(values, actions)
                    }
                  })
              }
            })
        }}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <div className={'form-block'}>
                <Input
                  name={'login'}
                  placeholder={'Login'}
                  variant={'login'}
                />
              </div>

              <div className={'form-block'}>
                <Input
                  name={'email'}
                  placeholder={'Email'}
                  variant={'email'}
                />
              </div>

              <div className={'form-block'}>
                <Input
                  name={'password'}
                  type={'password'}
                  placeholder={'Password'}
                  variant={'pass'}
                  passValidators={passValidators}
                  tip
                  tooltip
                />
              </div>

              {showCaptcha && <Captcha/>}

              <div className={cn([
                'form-agree',
                { 'form-agree_isInvalid': !agreeCheck }
              ])}>
                <Field>
                  {({ form, field }: any) => (
                    <input
                      name={'agreement'}
                      id={'agreeInput'}
                      type="checkbox"
                      checked={field.value.agreement}
                      onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                        await form.setFieldValue('agreement', e.target.checked)
                        form.setFieldTouched('agreement', true, false)
                      }}
                    />
                  )}
                </Field>

                <label htmlFor={'agreeInput'}/>

                <span>{getLocalizeString('I agree with')}</span>

                <a
                  href={`https://surfe.${(appType === 'pro') ? 'pro' : 'be'}/site/terms-of-service`}
                  target="_blank"
                >
                  {getLocalizeString('Terms Of Service')}
                </a>
              </div>

              <div className={'form-block'}>
                <Button
                  submit
                  text={'Sign up '}
                  type={'blue'}
                  size={(appType === 'pro') ? 'big' : ''}
                  isLoading={isSubmitting}
                />
              </div>

              {(appType !== 'pro') && (
                <div className={'form-block'}>
                  <Button
                    text={'Already registered? Sign in!'}
                    type={'white'}
                    link={'/login'}
                  />
                </div>
              )}
            </>
          )
        }}
      </Form>
    </>
  )
}

export default Register
