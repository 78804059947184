import React, {FC, useEffect, useState} from 'react'
import cn from 'classnames'
import {Link} from "react-router-dom"
import getLocalizeString from "../../utils/getLocalizeString"
import {TButton} from "../../types"
import css from './Button.module.scss'

const Button: FC<TButton> = ({text, type = 'blue', link, submit, size, icon, isLoading = false, onClick}) => {
  const [classes, setClasses] = useState<any[]>([])

  useEffect(() => {
    setClasses([
      css.Button,
      css['Button_' + type],
      css['Button_' + size],
      {[css.Button_isLoading]: isLoading},
    ])
  }, [type, size, isLoading])

  return (
    link ? (
      <Link
        to={link}
        className={cn(classes)}
        onClick={() => onClick && onClick()}
      >
        {icon && (<span dangerouslySetInnerHTML={{__html: icon}}/>)}

        <span>{getLocalizeString(text)}</span>

        <div className={css.Button__Loader}>
          <svg
            width="44px"
            height="44px"
            viewBox="40 40 20 20"
            preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" fill="none" stroke="#c6d0dbaa" strokeWidth="2" r="6" strokeDasharray="28.274333882308138 11.42477796076938"/>
          </svg>
        </div>
      </Link>
    ) : (
      <button
        type={submit ? 'submit' : 'button'}
        className={cn(classes)}
        onClick={() => onClick && onClick()}
      >
        {icon && (<span dangerouslySetInnerHTML={{__html: icon}}/>)}

        <span>{getLocalizeString(text)}</span>

        <div className={css.Button__Loader}>
          <svg
            width="44px"
            height="44px"
            viewBox="40 40 20 20"
            preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" fill="none" stroke="#c6d0dbaa" strokeWidth="2" r="6" strokeDasharray="28.274333882308138 11.42477796076938"/>
          </svg>
        </div>
      </button>
    )
  )
}

export default Button
