import React, {FC, useEffect, useRef, useState} from 'react'
import {useDispatch} from "react-redux"
import cn from 'classnames'
import {useAppContext} from "../../AppContext"
import css from './Lang.module.scss'

const Lang: FC = () => {
  const dispatch = useDispatch()
  const {lang, appType} = useAppContext()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const langRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    document.addEventListener('mousedown', clickHandler)

    return () => {
      document.removeEventListener('mousedown', clickHandler)
    }
  }, [])

  const clickHandler = (e: MouseEvent) => {
    if ( !langRef.current?.contains(e.target as Node) ) {
      setIsOpen(false)
    }
  }

  const changeLang = (e: React.MouseEvent) => {
    e.preventDefault()
    dispatch({type: 'SET_LANG', payload: (lang === 'en-US') ? 'ru-RU' : 'en-US'})
    localStorage.setItem('lang', (lang === 'en-US') ? 'ru-RU' : 'en-US')
    setIsOpen(false)
  }

  return (appType === 'pro') ? (
    <div
      className={css.LangPro}
      onClick={(e) => changeLang(e)}
    >
      {(lang === 'en-US') ? (
        <img src="https://static.surfe.pro/tpl/land-adv/img/united-states.svg" alt="img" />
      ) : (
        <img src="https://static.surfe.pro/tpl/land-adv/img/russia.svg" alt="img" />
      )}
    </div>
  ) : (
    <div
      className={cn([
        css.Lang,
        {[css.Lang_isOpen]: isOpen}
      ])}
      ref={langRef}
    >
      <div
        className={css.Lang__Current}
        onClick={() => setIsOpen(prev => !prev)}
      >
        {(lang === 'en-US') ? (
          <img src="https://static.surfe.be/tpl/land-adv/img/united-states.svg" alt="img" />
        ) : (
          <img src="https://static.surfe.be/tpl/land-adv/img/russia.svg" alt="img" />
        )}

        <span>{lang === 'en-US' ? 'English' : 'Русский'}</span>

        <svg width="8" height="5" viewBox="0 0 8 5" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.0001 2.9554L6.82867 0.126831L7.63667 0.934831L4.0001 4.5714L0.363525 0.934831L1.17153 0.126831L4.0001 2.9554Z" />
        </svg>
      </div>

      <div className={css.Lang__DropdownWrap}>
        <a
          href={"#"} className={css.Lang__Dropdown}
          onClick={(e) => changeLang(e)}
        >
          {(lang === 'en-US') ? (
            <img src="https://static.surfe.be/tpl/land-adv/img/russia.svg" alt="img" />
          ) : (
            <img src="https://static.surfe.be/tpl/land-adv/img/united-states.svg" alt="img" />
          )}

          <span>{(lang === 'en-US') ? 'Русский' : 'English'}</span>
        </a>
      </div>
    </div>
  )
}

export default Lang
