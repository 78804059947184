import {FC, useEffect} from 'react'
import {useDispatch} from "react-redux"

const Empty: FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({
      type: 'SET_SHOW_POPUP',
      payload: {
        isOpen: true,
        type: 'fail',
        text: 'Page not found',
        actions: [
          {
            text: 'Sign in',
            link: '/login'
          },
          {
            text: 'Sign up',
            link: '/register'
          }
        ]
      }
    })
  }, [])

  return null
}

export default Empty
